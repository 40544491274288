@font-face {
  font-family: gerenaRegular;
  src: url('../public/font/pt-sans.regular.ttf');
}

@font-face {
  font-family: gerenaBold;
  src: url('../public/font/pt-sans.bold.ttf');
}

*{
  box-sizing: border-box;
  font-family: 'gerenaRegular';
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body{
  margin: 0;
  padding: 0;
  display: none;
  transition: background-color 0.5s ease-in-out;
}

.body-theme-light{
  display: block !important;
  background-color: rgb(240, 240, 240) !important;
}

.body-theme-dark{
  display: block !important;
  background-color: #b3b3b3 !important;
}

.stop-scroll{
  overflow: hidden;
}

.header-container{
  position: relative;
  width: 100%;
  height: 150px;
  background-color: rgb(0, 105, 173);
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  padding: 0 20px;
  justify-content: center;
  transition: height 0.6s ease-in-out;
}

.logo-container > img{
  width: 160px;
  height: auto;
}

.categories{
  display: flex;
  height: 100%;
}

.nav-link{
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.nav-link:hover{
  text-decoration: underline;
}

.nav-item{
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.nav-item:hover{
  background-color: rgb(250, 200, 17);
}

.nav-item:hover .nav-link{
  color: rgb(0, 105, 173);
}

.hidden-container{
  position: absolute;
  width: 100%;
  z-index: 100;
  padding: 0 50px;
}

.hidden-content{
  position: relative;
  width: 100%;
  height: 300px;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 10px;
  top: -20.5px;
}

.show-hidden{
  display: block;
}

.yellow{
  width: 100%;
  height: 10px;
  background-color: rgb(250, 200, 17);
}

.red{
  width: 100%;
  height: 10px;
  background-color: rgb(218, 40, 46);
}

.category-levski-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 50px;
  justify-content: space-around;
}

.category{
  font-family: gerenaBold;
}

.levski-subcategories{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 200px;
  border-right: 2px solid rgb(0, 105, 173);
  border-left: 2px solid rgb(0, 105, 173);
  margin-right: 40px;
}

.subcategory-links{
  text-decoration: none;
  color: black;
}

.levski-subcategory{
  display: flex;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  font-family: gerenaBold;
}

.levski-subcategory:hover{
  background-color: rgb(250, 200, 17);
}

.subcategory-links.isDark-Font:hover{
  color: black !important;
}

.levski-post-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 45px;
  width: 100%;
  justify-content: space-between;
}

.levski-post-pic , .other-post-pic{
  width: 210px;
  height: 150px;
  overflow: hidden;
}

.levski-post-pic::before,
.other-post-pic::before,
.main-content-first-post::before,
.secondary-img-wrapper::before,
.search-result-img-wrapper::before
 {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.levski-post-pic > img, .other-post-pic > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
}

.levski-post-txt, .other-post-txt{
  padding-top: 10px;
}

.other-post-txt{
  position: absolute;
}

.loading-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader{
  display: block;
  position: relative;
  height: 40px;
  width: 280px;
  background-image: 
  linear-gradient(rgb(250, 200, 17) 50px, transparent 0), 
  linear-gradient(rgb(250, 200, 17) 20px, transparent 0), 
  linear-gradient(rgb(250, 200, 17) 20px, transparent 0), 
  linear-gradient(rgb(250, 200, 17) 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: 0 0, 40px 0, 80px 0, 120px 0;
  animation: pgfill 1s linear infinite;
}

.reposition{
  padding-left: 170px;
}

.post-time, .post-time-main-content{
  position: absolute;
  bottom: 0;
  color: white;
  padding: 5px;
  z-index: 3;
}

@keyframes pgfill {
  0% {   background-image: linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0); }
  25% {   background-image: linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0); }
  50% {   background-image: linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0); }
  75% {   background-image: linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(250, 200, 17)  20px, transparent 0); }
  100% {   background-image: linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0), linear-gradient(rgb(0, 105, 173) 20px, transparent 0); }
}


.efbet{
  /*width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 300px;
  transition: padding 0.6s ease-in-out;*/
  display: none !important; 
}

.home-wrapper{
  margin-top: 5px !important;
}

.efbet a{
  position: relative;
  width: 100%;
  height: auto;
}

.efbet a > img{
  width: 100%;
  object-fit: cover;
  height: auto;
}

.levski-post-link{
  text-decoration: none;
  color: black;
  position: relative;
  width: 210px;
  height: 150px;
  text-align: left;
}

.levski-post-link:hover{
  cursor: pointer;
}

.levski-post-link:hover .levski-post-pic > img, .other-post-link:hover .other-post-pic > img{
  transform: scale(1.2);
}

.basketball-post-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 40px;
  width: 100%;
  justify-content: space-between;
}

.other-post-link{
  text-decoration: none;
  color: black;
  position: relative;
  text-align: left;
}


.icon-1, .icon-2, .icon-3 {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 25px;
  height: 2px;
  background-color: black;
  transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
}

.icon-1 {
transform: translateY(-8px);
animation-delay: 100ms;
}

.icon-3 {
transform: translateY(8px);
animation-delay: 250ms;
}

.hamburger-icon {
  position: relative;
  height: 50px;
  width: 50px;
  z-index: 9999;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  background: rgba(255,255,255,0.2);
  display: none;
}

.hamburger-icon:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}

.icon-1.a {
  transform: rotate(40deg);
}
.icon-3.b {
  transform: rotate(-40deg);
}
.icon-2.c {
  opacity: 0;
}

.clear {
  clear: both;
}

@keyframes slideIn {
  0% {
    width: 0%;
    opacity: 0;
  }
  
  100% {
    width: 50%;
    opacity: 1;
  }
}

.hamburger-menu{
  position: fixed;
  width: 0px;
  height: 100vh;
  z-index: 9998;
  display: flex;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: white;
  transition: width 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 26px;
}

.hamburger-menu ul{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 50px 0px;
}

.hamburger-menu li{
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger-menu li:hover{
  background-color: rgb(0, 105, 173);
  cursor: pointer;
}

.hamburger-menu li:hover .hidden-categories{
  color: white;
}

.hamburger-menu-active{
  width: 100vw;
}

.hamburger-menu::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
}


.hidden-categories{
  text-decoration: none;
  color: black;
  width: 100%;
}

.toggle-first-hidden{
  display: none !important;
}

.second-hidden{
  display: none !important;
}

.toggle-second-hidden{
  display: flex !important;
}

.search{
  cursor: pointer;
  width: 30px;
}

.search-container{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -100;
  height: 0;
  transition: height 0.5s ease-in-out;
  background-color: rgb(44, 44, 44);
}

.search-active{
  z-index: 1;
  height: 80px;
}

.search-input{
  width: 0;
  height: 0;
  background-color: rgb(240, 240, 240);
  border: none;
  outline: none;
  width: 0px;
  height: 0px;
  padding: 0;
  transition: all 0.5s ease-in-out;
}

.search-input-wrapper{
  align-items: center;
  display: flex;
}

.input-icon{
  margin-left: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.input-active{
  width: 700px;
  height: 40px;
  padding-left: 10px;
  z-index: 1;
}

.post-page-wrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px 150px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  transition: margin 0.6s ease-in-out;
}

.single-post{
  position: relative;
  width: 750px;
  overflow: hidden;
}

.video{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.video-main{
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 255px;
    transition: width 0.6s ease-in-out;
}

.video iframe, .video-main iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single-post h1{
  font-size: 33px;
}

.single-post h3{
  font-size: 14px;
  font-weight: normal;

}

.single-post h3>span{
  padding-left: 5px;
  font-size: 14px;
  font-weight: normal;
}

.post-page-author > a{
  font-size: 16px;
}

.single-post img, .single-post h1{
  width: 100%;
}

.single-post img{
  object-fit: cover;
}

.single-post h3>span{
  font-weight: bold;
}

.content-img{
  width: 100%;
  height: auto;
  object-fit: cover;
}

.post-text{
font-size: 20px;
word-spacing: 0.1em;
}

.share-post{
  margin-top: 10px;

}

.loading-container-post-page{
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.box {
  width: 40px;
  height: 140px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: grey;
  bottom: 0;
  border-radius: 100%;
  transform: scaleX(.8);
  opacity: .6;
  animation: shadowScale 1s linear infinite;
}

.gravity {
  width: 40px;
  height: 40px;
  animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
}

.ball {
  width: 40px;
  height: 40px;
  background-image: url('./assets/icons/ball.png');
  background-size: cover;
  animation: roll .7s linear infinite;
}

.st-btn{
  padding: 0 40px !important;
  display: inline-block !important;
}

.share-post{
  margin-top: 20px;
}

.latest-wrapper{
  flex: 1;
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  flex-direction: column;
  margin-left: 40px;
  gap: 40px;
  height: 710px;
}

.latest-container{
  width: 350px;
  height: 450px;
  background-color: rgb(0, 105, 173);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-in-out;
}

.flash-home{
  position: relative;
  width: 50%;
  left: 50%;
  transform: translate(-50%);
}

.flashscore-post{
  width: 350px;
  transition: width 0.5s ease-in-out;
}

.flashscore-post img{
  width: 100%;
}

.aside-title{
  font-family: gerenaBold;
  color: white;
  width: 100%;
  border-bottom: 2px solid rgb(250, 200, 17);
  margin-bottom: 10px;
}

.latest-column{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.latest-content{
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: left;
  text-decoration: none;
  color: black;
}

.latest-title{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  overflow-wrap: break-word;
  font-size: 14px;
}

.latest-title div:nth-child(1){
  font-family: gerenaBold;
  overflow-wrap: break-word;
}

.latest-img-wrapper{
  width: 100px;
  height: 65px;
  overflow: hidden;
}

.latest-content img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.latest-content:hover img{
  transform: scale(1.2);
}

.latest-content:hover {
  color: white;
}

.post-follow-btns{
  text-align: center;
  width: 350px;
}

.post-follow-btns-footer{
  text-align: center;
}

.post-follow-btns p, .post-follow-btns-footer p{
  font-family: gerenaBold;
}

.post-follow-btns .st-btn, .post-follow-btns-footer .st-btn{
  padding: 0 10px !important;
}

.post-ad{
  padding-top: 30px;
}

.post-article-date{
  position: absolute;
  right: 0;
}

.prev-next-post{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  font-size: 15px;
}

.prev-next-post > a{
  text-decoration: none;
  color: black;
  text-transform: uppercase;
}

.prev-next-post > a div{
  font-family: gerenaBold;
  border-bottom: 2px solid black;
}

.prev-next-post .isDark-Font{
  font-family: gerenaBold !important;
  border-bottom: 2px solid white !important;
}

.post-page-author > a {
  font-family: gerenaBold;
  color: rgb(0, 105, 173);
  text-transform: uppercase;
  text-decoration-skip-ink: none;
}

.more-author-category{
  margin-top: 50px;
  display: flex;
  width: 100%;
  border-bottom: 3px solid rgb(0, 105, 173);
}

.more-author-category span{
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 15px;
  cursor: pointer;
}

.blue-span{
  background-color: rgb(0, 105, 173);
  color: white;
}

.more-posts-container{
  position: relative;
  max-width: 200px;
  cursor: pointer;
  display: block;
  height: 215px;
  color: black;
  text-decoration: none;
}

.front-more-posts-container{
  position: relative;
  max-width: 200px;
  cursor: pointer;
  display: block;
  height: 220px;
  color: black;
  text-decoration: none;
}

.more-posts-img-wrapper , .front-more-posts-img-wrapper{
  width: 200px;
  height: 150px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  transition: width 0.6s ease-in-out;
}

.more-posts-img-wrapper::before , .front-more-posts-img-wrapper::before , .front-first-small::before, .front-second-small::before, .front-news-wrapper-big::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.more-posts-img-wrapper img, .front-more-posts-img-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-more{
  margin: 0 150px;
  background-color: white;
  transition: margin 0.6s ease-in-out;
  padding: 0px 10px;
}

.my-carousel-wrapper, .my-carousel-wrapper-telegraph{
  position: relative;
  padding: 50px 0;
}


.react-multiple-carousel__arrow::before {
  color:black !important; 
}

.react-multiple-carousel__arrow {
  background: rgb(250, 200, 17) !important;
}

.home-container{
  position: relative;
  width: 100vw;
}

.home-wrapper{
  position: relative;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  margin: 0 150px;
  transition: margin 0.6s ease-in-out, background-color 0.5s ease-in-out;
  
}

.front-news-container{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  justify-content: center;
  height: 500px;
}

.slider-txt{
  font-family: gerenaBold;
}

.front-news-wrapper-big{
  position: relative;
  width: 60%;
  cursor: pointer;
}

.front-news-wrapper-small{
  position: relative;
  width: 40%;
}

.front-first-small, .front-second-small{
  width: 100%;
  height: 50%;
  cursor: pointer;
  position: relative;
}

.front-news-wrapper-big, .front-news-wrapper-small{
  overflow: hidden;
}

.front-news-wrapper-small{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.front-news-wrapper-big > img, .front-first-small > img, .front-second-small > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.front-news-text-container{
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  z-index: 2;
  padding: 10px;
}

.front-news-text-big{
  font-size: 35px;
}

.front-news-text-small{
  font-size: 20px;
}

.front-news-text-big , .front-news-text-small {
  padding-bottom: 12px;
}

.front-news-text-container:nth-child(2) span{
  font-size: 11px;
}

.front-news-text-container:nth-child(2){
  font-size: 13px;
}

.article-category{
  font-family: gerenaBold;
  margin-left: 5px;
}



.front-all-levski{
  width: 100%;
  height: 50px;
  background-color: rgb(0, 105, 173);;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.front-ad{
  position: relative;
  width: 100% !important;
  margin: 50px 0;
  text-align: center;
}

.rectangle{
  width: 100%;
  text-align: center;
}

.ad-wrap{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-ad{
  position: relative;
  width: 100%;
}

.home-link, .home-other-news-link{
  text-decoration: none;
}

.front-all-levski:hover{
  background-color: rgb(250, 200, 17);
  color: rgb(0, 105, 173);
}

.front-more-posts-img-wrapper div{
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  z-index: 2;
  padding: 10px;
  font-size: 10px;
}

.more-posts-calendar > img{
  width: 10px;
}

.home-category-title, .home-category-title-dark{
  padding: 0 5px;
}

.home-category-title h2 , .home-category-title-dark h2{
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.home-category-title h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}

.home-category-title-dark h2:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: white;
}

.home-category-title h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}

.home-category-title-dark h2:after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: white;
}

figure{
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
}

figure img{
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
}

@keyframes roll {
  0% {}
  100% { transform: rotate(360deg) }
}
@keyframes bounce {
  0% {}
  50% { transform: translateY(100px) }
  100% {}
}
@keyframes shadowScale {
  0% {}
  50% { transform: scaleX(1); opacity: .8;}
  100% {}
}

.breadcrumbs{
  position: relative;
  width: 100%;
}

.breadcrumbs a{
  text-decoration: none;
  color: black;
  font-family: gerenaBold;
  font-size: 10px;
}

.breadcrumbs img{
  width: 10px;
}

.breadcrumb-icon{
  margin-right: 10px;
}

.breadcrumbs ul{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 !important;
  padding-bottom: 40px !important;
  padding-left: 0 !important;
}

.breadcrumbs ul li{
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
}

.tema-sport-container, .match-telegraph-container{
  width: 100%;
}

.tema-sport-wrapper{
  background-color: rgb(0, 105, 173);
  border-radius: 10px;
}

.match-telegraph-wrapper{
  background-color: rgb(250, 200, 17);
  border-radius: 10px;
}

.my-carousel-wrapper-telegraph .react-multiple-carousel__arrow{
  background-color: rgb(0, 105, 173) !important;
}

.home-other-news-flex{
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.home-other-news-link{
  width: 200px;
}

.page-numb{
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 10px;
  background-color: rgb(0, 105, 173);
  color: white;
}

.active-page {
  font-weight: bold;
  color: rgb(250, 200, 17);
}

.dots-numb{
  position: relative;
  display: inline-block;

  height: 30px;
  text-align: center;
  line-height: 30px;
}

.flex-pagination{
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  margin-top: 40px;
}

.pagination-numbers{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.main-content-wrapper{
  position: relative;
  margin: 0 50px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  transition: margin 0.6s ease-in-out;
}

.main-sticky-wrapper{
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  flex-direction: column;
  margin-left: 40px;
  gap: 40px;
  height: 710px;
}

.flex-main-container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.main-content-title{
  font-size: 30px !important;
}

.main-content-link{
  text-decoration: none;
  color: black;
}

.main-content-link:hover, .main-content-secondary-link:hover{
  text-decoration: underline;
}

.main-content-first-post{
  position: relative;
}

.main-content-first-post-wrapper{
  margin-bottom: 40px;
}

.post-time-main-content img{
  width: 15px;
}

.main-content-secondary-link{
  text-decoration: none;
  color: #333;
}

.main-secondary-post{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 200px;
  align-items: center;
  margin-bottom: 20px;
}

.secondary-img-wrapper{
  width: 250px;
  height: 100%;
  position: relative;
  transition: width 0.6s ease-in-out;
}

.secondary-img-wrapper > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.secondary-info{
  flex: 1;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  justify-content: space-around;
}

.secondary-info h4{
  margin: 0 !important;
  font-family: gerenaBold;
}

.post-time-author img{
  width: 15px;
}

.post-time-author{
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-posts{
  position: relative;
  width: 750px;
  overflow: hidden;
}

.secondary-gray{
  background-color: rgb(240, 240, 240);
}

.main-arrows{
  width: 20px !important;
}

.next-page-button, .prev-page-button{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.prev-page-button, .next-page-button, .page-numb{
  cursor: pointer;
}

.prev-page-button, .next-page-button{
  width: 30px;
  height: 30px;
}

.main-video-player-cont, .vbox-player, #html5player{
  display: none !important;
} 

.wp-block-heading{
  display: none !important;
}

.load-more-btn{
  width: 250px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
}

.search-page-container{
  position: relative;
  width: 100vw;
}

.search-page-wrapper{
  position: relative;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  margin: 0 150px;
  transition: margin 0.6s ease-in-out;
}

.search-page-wrapper > h2{
  text-align: center;
}

.search-page-flex-container{
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.search-result-post{
  position: relative;
  width: 300px;
}

.search-result-post a{
text-decoration: none;
color: black;
}

.search-result-img-wrapper{
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.search-result-img-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.search-result-post:hover .search-result-img-wrapper img{
  transform: scale(1.2);
}

.load-more-wrapper{
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-result-title{
  text-align: center;
  width: 100%;
}

.footer{
  width: 100vw;
  position: relative;
  display: flex;
  background-color: rgb(0, 105, 173);
  color: white;
  padding: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  margin-top: 5px;
}

.logo-footer{
  position: relative;
  max-width: 200px;
  text-align: center;
}

.logo-footer p{
  margin: 0 !important;
}

.frontend{
  text-align: center;
}

.logo-footer p , .frontend p{
  font-family: gerenaBold;
}

.donate{
  position: fixed;
  top: 270px;
  width: 120px;
  height: 120px;
  z-index: 2;
  background-color: rgb(250, 200, 17);
  right: 10px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 4px solid rgb(0, 105, 173);
  cursor: pointer;
  justify-content: center;
  transform: rotateZ(25deg);
  transition: transform 0.2s ease-in-out;
  color: rgb(0, 105, 173);
  z-index: 1001;
  text-align: center;
}

.donate div{
  font-family: gerenaBold;
  font-size: 16px;
}

.donate:hover {
  transform: rotateZ(0deg);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10009;
}

.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .close-ad{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10010;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 105, 173);
  cursor: pointer;
}

.close-ad>span{
position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-ad>span::before, .close-ad>span::after{
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-ad>span::before{
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-ad>span::after{
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-ad{
  position: absolute;
  width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-page-flex-sliders{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 40px;
  gap: 20px;
  margin-bottom: 40px;
}

.CookieConsent{
  z-index: 1001 !important;
}

.consent-button{
  border-radius: 10px !important;
}

.consent-accept{
  background-color: rgb(250, 200, 17) !important;
}

.wp-block-jetpack-slideshow_swiper-wrapper, .swiper-wrapper{
  list-style: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  margin-top: 25px !important;
}

.privacy{
  color: white;
  font-family: gerenaBold;
}

.privacy-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.privacyUl{
  list-style: none;
  margin: 0 !important;
  padding: 20px 10px !important;
}

.privacyUl span {
  font-family: gerenaBold;
}

.home-page-flex-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 50px;
}

.flash-home{
  margin-top: 40px;
}

.iframe-in-text{
  width: 100% !important; 
}

.external-media-iframe{
  width: 100% !important;
  height: 100% !important;
}

.video-container-fb-vertical {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}

.external-media-iframe-vertical{
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 50% !important;
  height: 100% !important;
  transform: translate(-50%, -50%) !important;
}

.theme-switcher-container{
  position: absolute;
  top: 15px;
  right: 15px;
}

.isDark{
  background-color: #333 !important;
  color: white !important;
}

.isDark-Font{
  color: white !important;
}

.gpt-ad-section{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:498px){
  .volley-section{
    margin-bottom: 40px;
  }
  
  /* .gym-section{
    margin-top: 40px;
  } */
}


@media screen and (min-width: 699px){
  .modal-overlay{
    display: none;
  }
  
}

@media screen and (max-width:1250px){
  .video-main{
    width: 300px;
    height: 170px;
  }
  
  .main-content-title{
    font-size: 24px !important;
  }

  .flex-main-container{
    justify-content: center;
  }

  .main-posts{
    width: 100% !important;
  }
  
}

@media screen and (max-width: 1049px) {
  .main-sticky-wrapper{
    display: none;
  }
  
}

@media screen and (min-width:899px) and (max-width: 1250px){

  .secondary-info{
    justify-content: space-evenly;
  }

  .latest-container, .post-follow-btns, .flashscore-post{
    width: 260px;
  }

  .latest-title{
    font-size: 12px;
  }

  .post-page-wrapper, .home-wrapper, .search-page-wrapper{
    margin: 0px 100px;
  }

  .carousel-more{
    margin: 0 100px;}

  .efbet{
    padding: 0px 100px;
  }
}

@media screen  and (min-width:1101px) and (max-width: 1250px){
  .st-btn{
    padding: 0 30px !important;
    display: inline-block !important;

  }
  .post-follow-btns .st-btn, .post-follow-btns-footer .st-btn{
    padding: 0 10px !important;
  }

  .front-news-text-big{
    font-size: 30px;
  }
  
  .front-news-text-small{
    font-size: 20px;
  }
}

@media screen  and (min-width:899px) and (max-width: 1100px){
  .st-btn{
    padding: 0 20px !important;
    display: inline-block !important;

  }

  .post-follow-btns .st-btn, .post-follow-btns-footer .st-btn{
    padding: 0 10px !important;
  }

  .front-news-text-big{
    font-size: 25px;
  }
  
  .front-news-text-small{
    font-size: 15px;
  }
}

@media screen and (max-width: 898px) {
  .donate{
    width: 100px;
    height: 100px;
    border: 4px solid rgb(0, 105, 173);
  }

  .donate div{
    font-size: 13px;
  }

  .pagination-numbers{
    gap: 10px;
  }

  .front-news-text-big , .front-news-text-small {
    padding-bottom: 5px;
  }

  .latest-wrapper{
    display: none;
  }

  .front-more-posts-img-wrapper div{
    font-size: 10px;
  }
}

@media screen and (min-width: 600px) and (max-width: 898px){
  .front-news-container{
    height: 350px;
  }

  .front-news-text-container div:nth-child(2){
    font-size: 12px;
  }

  .post-page-wrapper, .home-wrapper, .main-content-wrapper, .search-page-wrapper{
    margin: 0px 50px;
  }

  .carousel-more{
    margin: 0 50px;}

  .st-btn{
    padding: 0 20px !important;
    display: inline-block !important;

  }
  .efbet{
    padding: 20px 50px;
  }

  .front-news-text-big{
    font-size: 20px;
  }
  
  .front-news-text-small{
    font-size: 12px;
  }
}

@media screen and (max-width: 599px){
  .donate{
    top: 200px;
  }
  .secondary-info h4{
    font-size: 13px;
  }

  .main-page-post-content{
    font-size: 13px;
  }

  .home-other-news-link{
    width: 130px;
  }

  .article-info{
    display: none;
  }

  .post-page-wrapper, .home-wrapper, .main-content-wrapper, .search-page-wrapper{
    margin: 0px;
  }

  .search-page-wrapper{
    padding: 20px;
  }

  .carousel-more{
    margin: 0px;}


  .st-btn{
    padding: 0 10px !important;
    display: inline-block !important;

  }
  .efbet{
    padding: 0px;
  }

  .front-more-posts-img-wrapper div{
    display: none;
  }
}

@media screen and (min-width: 460px) and (max-width: 599px){
  .front-news-text-container div:nth-child(2){
    font-size: 12px;
  }

  .front-news-container{
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
  }
  
  .front-news-wrapper-big{
    width: 100%;
    height: auto;
  }

  .front-first-small, .front-second-small{
    width: 50%;
    height: auto;
  }

  
  .front-news-wrapper-small{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .front-news-text-big{
    font-size: 20px;
  }
  
  .front-news-text-small{
    font-size: 15px;
  }

  .breadcrumbs a{
    font-size: 9px;
  }
  
}

@media screen and (max-width: 460px) {

  .flex-pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
  }

  .pagination-numbers {
    order: 1;
  }

  .prev-page-button,
  .next-page-button {
    margin-top: 20px;
    order: 2;
  }

  .donate{
    top: 150px;
    width: 75px;
    height: 75px;
    border: 3px solid rgb(0, 105, 173);
  }

  .donate div{
    font-size: 10px;
  }

  .home-other-news-link{
    margin-right: 5px;
  }

  .breadcrumbs a{
    font-size: 8px;
  }

  .home-category-title h2 , .home-category-title-dark h2{
    font-size: 22px;
  }

  .front-news-text-container div:nth-child(2){
    font-size: 10px;
  }

  .home-wrapper{
    padding: 20px 0px !important;
  }
  .front-news-container{
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
  }
  
  .front-news-wrapper-big{
    width: 100%;
    height: 300px;
  }

  .front-first-small, .front-second-small{
    width: 50%;
    height: 180px;
  }
  .front-news-text-big{
    font-size: 18px;
  }
  
  .front-news-text-small{
    font-size: 12px;
  }

  .front-news-container{
    padding: 5px;
  }

  .front-news-wrapper-small{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  
}

@media screen and (min-width: 599px) and (max-width: 750px) {
  .front-more-posts-img-wrapper{
    width: 160px;
    height: 110px;
  }

  .front-more-posts-container{
    width: 160px;
    height: 185px;
  }
  
}

@media screen and (min-width: 360px) and (max-width: 599px) {
  .front-more-posts-img-wrapper{
    width: 150px;
    height: 100px;
  }
  .front-more-posts-container{
    width: 150px;
    height: 175px;
  }
  
}

@media screen and (max-width: 599px){
  .single-post h1{
    font-size: 24px;
  }

  .post-text{
    font-size: 18px;
  }

  .post-page-wrapper, .home-wrapper, .main-content-wrapper{
    padding: 20px;
  }

  .breadcrumbs ul{
    padding-bottom: 20px !important;
    margin: 0 !important;
    padding-left: 0 !important;
  }

  .prev-next-post{
    font-size: 12px;
  }
}


@media screen and (max-width: 1100px) {
  .nav-link{
    font-size: 16px;
  }
  .logo-container > img{
    width: 120px;
    height: auto;
  }
  
}

@media screen and (min-width:1031px) and (max-width: 1250px) {
  .isLast{
    display: none;
  }
  
}

@media screen and (min-width: 899px) and (max-width:1030px){
  .levski-post-link, .levski-post-pic{
    width: 160px;
    height: 100px;
    font-size: 15px;
  }
  .levski-post-wrapper{
    padding-top:65px ;
  }
  .isLast{
    display: none;
  }
}

@media screen and (max-width:898px) {
  .hidden-content{
    display: none;
  }
  .categories{
    display: none;
  }
  .hamburger-icon{
    display: block;
  }

  .header-container{
    justify-content: space-between;
    height: 100px;
  }

  .input-active{
    width: 250px;
  }
}

@media screen and (min-width: 899px) {

  .hamburger-menu{
    width: 0;
  }
  
}

@media screen and (max-width: 600px) {

  .logo-container > img{
    width: 110px;
    height: auto;
  }
}

@media screen and (max-width: 699px) {

  .secondary-img-wrapper{
    width: 150px;
  }

  .main-secondary-post{
    height: 150px;
  }
}

@media screen and (min-width: 361px) and (max-width: 489px) {
  .author-slider{
    width: 160px !important;
    height: 175px !important;
  }

  .author-slider .more-posts-img-wrapper{
    width: 160px !important;
    height: 110px !important;
  }
}


@media screen and (max-width: 360px) {

  .main-page-post-content{
    display: none;
  }


  .slider-txt{
    font-size: 14px;
  }

  .front-news-text-big{
    font-size: 14px;
  }

  .home-category-title h2, .home-category-title-dark h2 {
    font-size: 18px;
  }

  .front-news-text-small{
    font-size: 10px;
  }

  .more-posts-img-wrapper, .front-more-posts-img-wrapper{
    width: 120px;
    height: 80px;
  }

  .front-more-posts-container{
    width: 120px;
    height: 155px;
  }

  .more-posts-container{
    height: 145px;
    max-width: 150px;
  }

  .more-author-category{
    font-size: 13px;
  }

  .logo-container > img{
    width: 100px;
    height: auto;
  }

  .search{
    width: 25px;
  }

  .hamburger-icon{
    width: 40px;
    height: 40px;
  }
  .icon-1, .icon-2, .icon-3{
    left: 25%;
    width: 20px;
  }
}

@media screen and (max-width: 390px) {
  .author-slider{
    height: 145px !important;
    max-width: 135px !important;
  }

  .author-slider .more-posts-img-wrapper{
    width: 135px !important;
    height: 90px !important;
  }
}